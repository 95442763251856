import React from "react";
import ImageWrapper from "./ImageWrapper";
import Link from "next/link";

function CmsCardGroup({ component, page_id='' }) {
  return (
    <div
      className={`griglia grid-cols-${component.options.itemsPerRow}`}
      id={`${component.id ?? ""}`}
    >
      {component.options.cards.map(function (card, i) {
        return (
          <div key={`card_${i}`} data-aos="fade-up">
            <div className="text-center card">
              <div className="image">
                <div className="bg-image"></div>
                {card.image.url != "" && (
                  <div>
                    <ImageWrapper
                      alt=""
                      src={card.image.url}
                      blur={card.image.urlp}
                      layout="responsive"
                      width={card.image.width}
                      height={card.image.height}
                      // priority={true}
                    />
                  </div>
                )}
              </div>
              <div className="cardContent">
                <div
                  className="caption"
                  dangerouslySetInnerHTML={{ __html: card.caption }}
                />
                <div className="p-0">
                  {typeof card.url != "undefined" && card.url != "" && (
                    <Link href={card.url}>
                      <a
                        className="card-btn"
                        target={
                          card.url.startsWith("http") ? "_blank" : "_self"
                        }
                      >
                        { card.link_text != '' ? card.link_text : 'READ MORE' }
                      </a>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default CmsCardGroup;
